<template>
  <el-main>
    <!-- 左侧手机显示 -->
    <div class="phoneBox" :style="{ '--bg': `url(${imgUrlHead}phonebg.png)` }">
      <!-- 手机顶部小图标栏 -->
      <div class="topFixed-top">
        <div>
          <i class="iconfont icon-dian" v-for="(item, index) in 4" :key="index"></i>
          <i class="iconfont icon-iconhuizong_huaban1fuben3"></i>
        </div>
        <div>9.00AM</div>
        <div>
          100%
          <i class="iconfont icon-dianchi" style="font-size: 22px"></i>
        </div>
      </div>
      <div class="showTopBgBig">
        <div class="showTopBg" :style="{ '--bg': `url(${imgUrlHeadO2O}diyTopBg.png)` }">
          <div @click="chooseUpdateThat(item)" v-for="item in diyInfo" :key="item.id">
            <Uicomponents :action="action" :componentStyle="item" :class="[activeID === item.id ? 'active' : '']"></Uicomponents>
            <div style="height: 20px"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="controlBox">
      <div class="titleBox">{{ getActiveName }}</div>
      <div class="controlInfo">
        <Uicontrol :controlIndex="activeThat.style" :controlDefault="activeThat.config" @getRes="getResult"></Uicontrol>
      </div>
    </div>
    <Preservation @preservation="Preservation"></Preservation>
  </el-main>
</template>

<script>
import { imgUrlHead } from '@/util/config';
import Uicomponents from '@/views/addPages/uicomponents/index';
import Uicontrol from '@/views/addPages/uicomponents/controlIndex';
import { GenerateComponent } from '@/util/componentsList.js';
import Preservation from '@/components/preservation';
export default {
  components: {
    Uicomponents,
    Uicontrol,
    Preservation,
  },
  data() {
    return {
      isFirstLoad: true,
      imgUrlHead,
      imgUrlHeadO2O: imgUrlHead + 'O2O/',
      //  页面装修数据
      diyInfo: [],
      // 历史遗留问题 不知道干啥
      action: {},
      // 用于确定当前选中哪个元素
      activeID: 0,
      // 当前选中组件  -> 默认style为一个没使用过的数
      activeThat: {
        style: -10,
      },
    };
  },
  created() {
    this.getDiyInfo();
  },
  computed: {
    getActiveName() {
      let name = '';
      switch (Number(this.activeThat.style)) {
        case 1:
          name = '轮播图';
          break;
        case 11:
          name = '标题栏';
          break;
        case 3:
          name = '按钮组';
          break;
        case 4:
          name = '图片橱窗';
          break;
        case 6:
          name = '公告栏';
          break;
        default:
          name = '未知错误';
          break;
      }
      return name;
    },
  },
  methods: {
    // 第一次进入页面时 初始化数据 按次加入所需组件
    firstInit() {
      // 添加banner
      this.diyInfo.push({
        id: 1,
        style: 1,
        config: GenerateComponent(1),
      });
      // 添加标题栏
      this.diyInfo.push({
        id: 2,
        style: 11,
        config: GenerateComponent(11),
      });
      // 添加按钮组
      this.diyInfo.push({
        id: 3,
        style: 3,
        config: GenerateComponent(3),
      });
      // 添加图片橱窗
      this.diyInfo.push({
        id: 4,
        style: 4,
        config: GenerateComponent(4),
      });
      // 添加公告栏
      this.diyInfo.push({
        id: 5,
        style: 6,
        config: GenerateComponent(6),
      });
      // 添加banner
      this.diyInfo.push({
        id: 6,
        style: 1,
        config: GenerateComponent(1),
      });
    },
    chooseUpdateThat(data) {
      this.activeID = data.id;
      this.activeThat = data;
    },
    // 获取控制台修改后的配置
    getResult(config, action, index) {
      if (action) {
        this.action = action;
      }
    },
    // 点击保存 保存配置
    Preservation() {
      this.$confirm('此操作将保存该首页配置, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let obj = {
            page_name: '智慧O2O首页',
            page_type: 1,
            show_type: '2,3,4,5,6,7',
            page_value: JSON.stringify(this.diyInfo),
          };
          if (!this.isFirstLoad) {
            obj.type = 1;
          }
          return this.$axios.post(this.$api.O2O.ShopDiypage.diypage, obj);
        })
        .then(res => {
          if (res.code === 0) {
            let str = this.isFirstLoad ? '保存配置成功' : '修改配置成功';
            this.$message.success(str);
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
    getDiyInfo() {
      this.$axios.post(this.$api.O2O.ShopDiypage.diypageList, { type: 1 }).then(res => {
        if (res.code === 0) {
          if (!res.result.page_values) {
            this.firstInit();
            this.chooseUpdateThat(this.diyInfo[0]);
          } else {
            this.diyInfo = JSON.parse(res.result.page_values);
            this.chooseUpdateThat(this.diyInfo[0]);
            this.isFirstLoad = false;
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding-bottom: 60px;
  .phoneBox {
    width: 409px;
    height: 770px;
    background-size: 100% 100%;
    margin-right: 20px;
    background-image: var(--bg);
    padding: 54px 20px 66px 16px;
    display: flex;
    flex-direction: column;
    .topFixed-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      background-color: #d7d7d7;
      & > div {
        display: flex;
        align-items: center;
      }
      i {
        font-size: 12px;
      }
    }
    .showTopBgBig {
      flex: 1;
      background-color: #f1f7f4;
      overflow: hidden;
      overflow-y: auto;
      padding-bottom: 20px;
      &::-webkit-scrollbar {
        width: 0px;
      }
    }
    .showTopBg {
      padding-top: 45px;
      width: 100%;
      background-size: 100% 250px;
      background-image: var(--bg);
      background-repeat: no-repeat;
      .active {
        position: relative;
        &::before {
          content: '';
          position: absolute;
          top: -2px;
          left: 0px;
          width: calc(100% - 4px);
          height: 100%;
          border: 2px solid #155bd4;
          z-index: 1;
        }
      }
    }
  }
  .controlBox {
    width: 600px;
    min-height: 300px;
    border-radius: 10px;
    box-shadow: 0 2px 12px 0 #c8c8c8;
    padding: 16px;
    margin-left: 50px;
    margin-right: 200px;
    .titleBox {
      border-bottom: 1px solid #eee;
      padding-bottom: 15px;
    }
    .controlInfo {
      max-height: 710px;
      overflow: hidden;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 4px;
      }
    }
  }
}
</style>
